// socket.js
import io from 'socket.io-client';
import { NodeURL } from './api';

let socket = null;

/**
 * Initialize the Socket.IO instance
 * @returns {Socket} The initialized Socket.IO client
 */
export const initializeSocketIo = () => {
  if (!socket) {
    socket = io(NodeURL, {
      // reconnection: true, // Enable reconnection if the connection drops
      // transports: ['websocket'], // Prefer WebSocket for better performance
    });

    // Optional: Add event listeners for debugging or default actions
    socket.on('connect', () => {
      console.log('Socket connected:', socket.id);
    });

    socket.on('disconnect', () => {
      // console.log('Socket disconnected.');
    });

    socket.on('connect_error', (err) => {
      console.error('Socket connection error:', err);
    });

   //  console.log('Socket initialized.');
  } else {
   //  console.warn('Socket is already initialized.');
  }

  return socket;
};

/**
 * Get the existing Socket.IO instance
 * @returns {Socket|null} The Socket.IO client or null if not initialized
 */
export const getSocketIo = () => {
  if (!socket) {
   //  console.error('Socket is not initialized. Call initializeSocketIo first.');
    return null;
  }
  return socket;
};

/**
 * Disconnect the Socket.IO instance
 */
export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
   //  console.log('Socket disconnected.');
    socket = null;
  } else {
   //  console.warn('Socket is not connected.');
  }
};
