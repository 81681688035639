import React from 'react'
import { Link } from 'react-router-dom'
import { MetaTags } from 'react-meta-tags'
import Header from '../header'
import Footer from '../NewFooter'
import './features.scss'
// Images
import compliance from '../../../images/feature-new/compliance/compliance_banner.png'
import centralised from '../../../images/feature-new/compliance/centralised_management.webp'
import automation from '../../../images/feature-new/compliance/automation_tasks.webp'

import compliance_subbanner1 from '../../../images/feature-new/compliance/compliance_subbanner1.webp'
import compliance_subbanner2 from '../../../images/feature-new/compliance/compliance_subbanner2.webp'
// import compliance_subbanner3 from '../../../images/feature-new/compliance/compliance_subbanner3.png'
// import compliance_subbanner4 from '../../../images/feature-new/compliance/compliance_subbanner4.png'

import enhanced from '../../../images/feature-new/compliance/enhanced.webp'
import secure_organisation from '../../../images/feature-new/compliance/secure_organisation.png'
import customisable_solutions from '../../../images/feature-new/compliance/customisable_solutions.png'
import { GetStartButton, GetStartCTA, GetStartCTATrail } from '.'

const Complainces = () => {
   return (
      <>
         <MetaTags>
            <title>Software for Compliance Management Solutions in UK - Getshifts</title>
            <meta name="keyword" content="compliance management software, leading compliance software, best compliance software" />
            <meta
               name="description"
               content="Easy to navigate regulatory compliance challenges with our advanced software solution. Simplify audits, manage risks, and ensure strict compliance with industry standards. Explore the power of our comprehensive compliance software for your organization today"
            />
         </MetaTags>
         <Header />
         <section className="cover-banners">
            <section className="home-banner">
               <div className="container">
                  <div className="banner-parts feature_parts">
                     <div className="left-banner">
                        <h4 className="sht-list1 new_sht-list1">Compliance</h4>
                        <h6>
                           Worry-Free Compliance <br />
                           by Get Shifts
                        </h6>
                        <p>
                           Get Shifts introduces Worry-Free Compliance, a crucial feature designed to alleviate compliance concerns for businesses. This
                           solution centralises record-keeping and automates compliance management processes, ensuring businesses maintain up-to-date
                           documentation and certifications in line with regulatory requirements, thus avoiding penalties, legal issues, and reputational
                           damage.
                        </p>
                        <GetStartButton />
                     </div>

                     <div className="right_banners">
                        <img src={compliance} alt="compliance" />
                     </div>
                  </div>
               </div>
            </section>
         </section>

         <section className="feature_content">
            <div className="container">
               <div className="feature_content_info">
                  <div className="feature_content_img">
                     <img src={centralised} alt="Centralised Compliance Management" />
                  </div>
                  <div className="feature_content_box">
                     <h6>Centralised Compliance Management</h6>
                     <p>
                        Worry-Free Compliance serves as a comprehensive tool that centralises the management of staff compliance requirements. By digitising
                        employee data, Get Shifts facilitates easy access and management of crucial information, ensuring adherence to industry standards and
                        regulations. This central repository mitigates risks associated with manual record-keeping and disparate systems, offering a
                        consolidated platform for effective compliance management.
                     </p>
                  </div>
               </div>
               <div className="feature_content_info">
                  <div className="feature_content_box">
                     <h6>Automation of Compliance Tasks</h6>
                     <p>
                        The automation feature of Worry-Free Compliance streamlines tracking certifications, licenses, and training records, significantly
                        reducing the potential for human error and ensuring that businesses remain audit-ready. Automated notifications for upcoming renewals or
                        expirations enhance proactive management of compliance requirements, minimising the risk of non-compliance.
                     </p>
                  </div>
                  <div className="feature_content_img">
                     <img src={automation} alt="Automation of Compliance Tasks" />
                  </div>
               </div>
            </div>
         </section>
         <GetStartCTA />

         <section className="feature_content">
            <div className="container">
               <div className="row mt-5">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature new__card">
                        <span className="feature__img">
                           <img src={enhanced} alt="Enhanced Transparency and Accountability" loading="lazy" />
                        </span>
                        <h6>Enhanced Transparency and Accountability</h6>
                        <p>
                           With Worry-Free Compliance, managers and HR professionals gain invaluable insights into employee compliance statuses, allowing for
                           proactive measures to maintain standards. The system's ability to generate detailed compliance reports is crucial for audits or
                           inspections, providing concrete evidence of adherence to regulatory standards.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature new__card">
                        <span className="feature__img">
                           <img src={secure_organisation} alt="Secure Digital Storage and Organisation" loading="lazy" />
                        </span>
                        <h6>Secure Digital Storage and Organisation</h6>
                        <p>
                           Sensitive employee information is securely stored and easily accessible by authorised personnel only, streamlining data management
                           and complying with data protection regulations. This safeguards against data loss or unauthorised access.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature new__card">
                        <span className="feature__img">
                           <img src={customisable_solutions} alt="Customisable Compliance Solutions" loading="lazy" />
                        </span>
                        <h6>Customisable Compliance Solutions</h6>
                        <p>
                           Customisation options within Worry-Free Compliance allow businesses to tailor the system to their specific needs. Administrators can
                           set up automated reminders, configure compliance requirements, and adapt the system to align with evolving regulatory landscapes,
                           ensuring the business remains agile in its compliance efforts.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="comp_subanners_section">
            <div className="comp_subanners">
               <div className="comp_subanner comp_subanners1">
                  <img src={compliance_subbanner1} loading="lazy" />
               </div>
               <div className="comp_subanner comp_subanners2">
                  <img src={compliance_subbanner2} loading="lazy" />{' '}
               </div>
               {/* <div className="comp_subanner comp_subanners3"><img src={compliance_subbanner3} loading='lazy'/> </div> */}
               {/* <div className="comp_subanner comp_subanners4"><img src={compliance_subbanner4} loading='lazy'/> </div> */}
            </div>
         </section>
         <GetStartCTATrail />

         <section className="new_cta_section">
            <div className="container">
               <h6>Discover How Worry-Free Compliance Can Transform Your Business</h6>
               <p>
                  Explore how Worry-Free Compliance by Get Shifts can streamline your compliance processes, reduce risks, and enhance operational transparency,
                  helping your business navigate regulatory complexities with confidence and focus on strategic growth.
               </p>
            </div>
         </section>

         <section className="feature_content">
            <div className="container">
               <div className="row mt-5">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature new__card">
                        <h6>Proactive Risk Management</h6>
                        <p>
                           Beyond simplifying compliance management, Worry-Free Compliance aids in risk management by providing a centralised platform for
                           compliance data, enabling businesses to proactively identify and address potential compliance gaps, and minimising the risk of
                           non-compliance and its associated consequences.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature new__card">
                        <h6>Streamlined Auditing Processes</h6>
                        <p>
                           Compliance also streamlines auditing processes by offering quick access to compliance records and reports, facilitating efficient and
                           thorough audits. This ease of access is invaluable during external audits or internal reviews, saving time and resources while
                           ensuring compliance transparency.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature new__card">
                        <h6>Cultivating a Compliance Culture</h6>
                        <p>
                           Furthermore, Worry-Free Compliance promotes a culture of compliance within the organisation. Providing employees with access to their
                           compliance records and relevant training materials empowers them to take responsibility for their compliance obligations, aligning
                           their actions with regulatory requirements and organisational standards.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         {/* <section>
                <div className="container">
                  <div className="features_cta">
                      
                        <div className="left-features_cta">
                            <img src={featuremincard1} alt="featuremincard1" />
                        </div> 
                        <div className="right-features_cta">
                            <h5>"Seamless Compliance Solutions: Relax, We've Got You Covered!"</h5>
                            <p>Let Get Shifts handle the staffing details, so you can concentrate on the heart of your business</p>
                            <div className="features_cta_btn">
                              <Link to="/signup">Get Started - <span style={{ fontSize: "12px" }}>It's free</span></Link>
                            </div>
                        </div>
                  </div>
                </div>
            </section> */}
         <Footer />
      </>
   )
}

export default Complainces
