import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import Context from '../../api/context'

// import logo from "../../images/common/logo.png"
import logo from '../../images/common/agency-logo.png'

import facebook from '../../images/common/facebook.png'

let NewFooter = props => {
   let siteContext = useContext(Context)
   let [facebookLink, setFacebookLink] = useState(''),
      [twitterLink, setTwitterLink] = useState(''),
      [siteAddress, setSiteAddress] = useState('')

   useEffect(() => {
      if (siteContext.facebookLink && siteContext.facebookLink !== '') {
         setFacebookLink(siteContext.facebookLink)
      }
      if (siteContext.twitterLink && siteContext.twitterLink !== '') {
         setTwitterLink(siteContext.twitterLink)
      }
      if (siteContext.siteAddress && siteContext.siteAddress !== '') {
         setSiteAddress(siteContext.siteAddress)
      }
   }, [siteContext])

   return (
      <>
         <section className="footer-section">
            <div className="container">
               <div className="footer-lists">
                  <div className="row">
                     <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="footer-logwid">
                           <Link to="/">
                              <img src={logo} alt="logo" className="foot-logo" />
                           </Link>
                           <ul className="mt-3">
                              <li>
                                 <span className="fts-right">
                                    Unit 5, Christie Fields Office Park, <br />
                                    Christie Way, Manchester M21 7QY
                                 </span>{' '}
                              </li>

                              <li>
                                 <span className="fts-right"> <a href="mailto:support@getshifts.co.uk">support@getshifts.co.uk</a>  </span>
                              </li>
                              <li>
                                 <span className="fts-right"> <a href="tel:+0330 808 3871">0330 808 3871</a> </span>{' '}
                              </li>
                           </ul>
                           <div className="footer-widths last-footers">
                              <ul>
                                 <li>
                                    <Link to={{ pathname: facebookLink }} target="_blank">
                                       {' '}
                                       <img src={facebook} alt="facebook" />{' '}
                                    </Link>
                                 </li>
                                 <li>
                                    <Link to={{ pathname: twitterLink }} target="_blank">
                                       {' '}
                                       <img src={`${process.env.PUBLIC_URL}/img/front/twitter.svg`} alt="twitter" />{' '}
                                    </Link>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="footer-widths">
                           <h5>Get Shifts</h5>
                           <ul>
                              <li>
                                 <Link to="/features"> Features </Link>
                              </li>
                              <li>
                                 <Link to="/application-feature">Mobile App Features </Link>
                              </li>
                              <li>
                                 <Link to="/pricing"> Pricing </Link>
                              </li>
                              <li>
                                 {/* <Link to="/pricing"> Resources </Link> */}
                                 <Link to="/resources"> Resources </Link>
                              </li>
                              <li>
                                 {/* <Link to="/pricing"> Resources </Link> */}
                                 <Link to="/helpcentre">Help Centre </Link>
                              </li>
                              {/* <li>
											<Link to=""> Security </Link>
										</li>
										<li>
											<Link to=""> Road Map </Link>
										</li> */}
                           </ul>
                        </div>
                     </div>
                     <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="footer-widths">
                           <h5>Company</h5>
                           <ul>
                              {/* <li>
										<Link to=""> About Us </Link>
										</li> */}
                              <li>
                                 <Link to="/terms-services"> Terms of Service </Link>
                              </li>
                              <li>
                                 <Link to="/anti-spam-policy"> Anti-Spam Policy </Link>
                              </li>
                              {/* <li>
										<Link to=""> Abuse Policy </Link>
										</li> */}
                              <li>
                                 <Link to="/privacy-policy"> Privacy Policy </Link>
                              </li>
                              <li>
                                 <Link to="/cookies-policy"> Cookie Policy </Link>
                              </li>
                              <li>
                                 <Link to="gdpr-compliance"> GDPR Compliance </Link>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="footer-widths  midls-footers">
                           <h5>Help</h5>
                           <ul>
                              <li>
                                 <Link to="/contact"> Contact </Link>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="footer-copyright">
               <p>
                  Get Shifts ©️ {new Date().getFullYear()}, {siteAddress || 'NXT Digital Ltd, Registered in England and Wales, Company Number: 13265961'}
                  <br />
               </p>
               {/* <p className="powered_tt">
                  Powered by{' '}
                  <a href="https://www.teamtweaks.com/" target="_blank">
                     Team Tweaks
                  </a>
               </p> */}
            </div>
         </section>
      </>
   )
}
export default NewFooter
