import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import Header from '../header'
import Footer from '../NewFooter'
import './features.scss'
// Images

import employee from '../../../images/feature-new/employee/employee_banner.png'
import employee_accordion from '../../../images/feature-new/employee/employee_accordion.webp'

import employee_db from '../../../images/feature-new/employee/employee_db.webp'
import interface_usability from '../../../images/feature-new/employee/interface_usability.webp'
import profile_communication from '../../../images/feature-new/employee/profile_communication.webp'

import all_feature from '../../../images/feature-new/employee/all_feature.webp'
import streamline_process from '../../../images/feature-new/employee/streamline_process.webp'
import overview_db from '../../../images/feature-new/employee/overview_db.webp'
import workforce_mangement from '../../../images/feature-new/employee/workforce_mangement.webp'
import { GetStartButton, GetStartCTA, GetStartCTATrail } from '.'

const Employees = () => {
   const [activeIndex, setActiveIndex] = useState(0)

   const toggleAccordion = index => {
      setActiveIndex(activeIndex === index ? 0 : index)
   }

   return (
      <>
         <MetaTags>
            <title>Employee engagement and management software in UK - Getshift</title>
            <meta name="keyword" content="custom employee management software, employee management system software, employee engagement software" />
            <meta
               name="description"
               content="Getshift is the best Employee Engagement and management software in the UK for your business. Find the highest rated Employee Engagement software in the UK pricing, reviews, trials, and more."
            />
         </MetaTags>
         <Header />
         <section className="home-banner">
            <div className="container">
               <div className="banner-parts feature_parts">
                  <div className="left-banner">
                     <h4 className="sht-list1 new_sht-list1">Employee</h4>
                     <h6>
                        Employee Management Portal
                        <br />
                        by Get Shifts
                     </h6>
                     <p>
                        Get Shifts introduces the Employee Management Portal, a revolutionary platform designed to transform how businesses manage employee
                        data. This intuitive system simplifies control over staff databases, allowing for quick viewing, editing, and updating of staff details,
                        thus streamlining the management of employee information.
                     </p>
                     <GetStartButton buttonStyle={{ width: '30%' }} />
                  </div>

                  <div className="right_banners">
                     <img src={employee} alt="employee" />
                  </div>
               </div>
            </div>
         </section>
         <section className="feature_content">
            <div className="container">
               <div className="row mt-5">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <div className="feature__img--img">
                           <img src={employee_db} alt="employee_db" loading="lazy" />
                        </div>
                        <h6>
                           Centralised and Comprehensive <br />
                           Employee Data Management
                        </h6>
                        <p>
                           The Employee Management Portal acts as a centralised hub for all employee-related activities, providing unparalleled control over
                           workforce data. It facilitates a range of tasks, from accessing basic employee information to managing role assignments and updating
                           contact details, significantly easing the burden of employee management.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <div className="feature__img--img">
                           <img src={interface_usability} alt="interface_usability" loading="lazy" />
                        </div>
                        <h6>
                           Intuitive Interface and Enhanced
                           <br /> Usability
                        </h6>
                        <p>
                           Featuring an intuitive interface, the portal simplifies navigation and eliminates the need for cumbersome systems or multiple
                           spreadsheets. This design ensures that managers and HR professionals can efficiently perform necessary tasks, enhancing productivity
                           and reducing administrative time.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <div className="feature__img--img">
                           <img src={profile_communication} alt="profile_communication" loading="lazy" />
                        </div>
                        <h6>
                           Advanced Communication and Profile
                           <br />
                           Management
                        </h6>
                        <p>
                           The portal enhances communication within the organisation by incorporating email functionalities, allowing for easy sharing of
                           profiles and updates. Employees can update their profiles directly on the app, ensuring that their information is always current. The
                           system also allows for the easy addition and invitation of new employees, streamlining the onboarding process.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <GetStartCTA />
         <section className="feature_content">
            <div className="container">
               <div className="row mt-5">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
                     <div id="accordion" className="employee_accordion">
                        <div className="card">
                           <div className="card-header" id="headingOne">
                              <button
                                 className={`btn btn-link ${activeIndex === 0 ? 'active' : ''}`}
                                 onClick={() => toggleAccordion(0)}
                                 aria-expanded={activeIndex === 0}
                              >
                                 Flexibility and Accessibility
                              </button>
                           </div>

                           <div
                              id="collapseOne"
                              className={`collapse ${activeIndex === 0 ? 'show' : ''}`}
                              aria-labelledby="headingOne"
                              data-parent="#accordion"
                           >
                              <div className="card-body">
                                 As a web-based platform, the Employee Management Portal offers the flexibility to manage employee details from any device with
                                 internet access, allowing authorised users to access the system anytime, anywhere. This feature is especially beneficial for
                                 managers and HR personnel who require constant access to update or retrieve information on the go.
                              </div>
                           </div>
                        </div>
                        <div className="card">
                           <div className="card-header" id="headingTwo">
                              <button
                                 className={`btn btn-link ${activeIndex === 1 ? 'active' : ''}`}
                                 onClick={() => toggleAccordion(1)}
                                 aria-expanded={activeIndex === 1}
                              >
                                 Customisation, Integration, and Advanced Features
                              </button>
                           </div>

                           <div
                              id="collapseTwo"
                              className={`collapse ${activeIndex === 1 ? 'show' : ''}`}
                              aria-labelledby="headingTwo"
                              data-parent="#accordion"
                           >
                              <div className="card-body">
                                 Organisations can customise the portal to include specific fields that capture information pertinent to their operations, such
                                 as professional certifications and emergency contact details. Seamless integration with other business systems, like payroll
                                 software or scheduling platforms, ensures consistency and accuracy across all data points.
                              </div>
                           </div>
                        </div>
                        <div className="card">
                           <div className="card-header" id="headingThree">
                              <button
                                 className={`btn btn-link ${activeIndex === 2 ? 'active' : ''}`}
                                 onClick={() => toggleAccordion(2)}
                                 aria-expanded={activeIndex === 2}
                              >
                                 Comprehensive Overview and Data Management
                              </button>
                           </div>

                           <div
                              id="collapseThree"
                              className={`collapse ${activeIndex === 2 ? 'show' : ''}`}
                              aria-labelledby="headingThree"
                              data-parent="#accordion"
                           >
                              <div className="card-body">
                                 The portal provides a comprehensive overview of employees, enhancing visibility and management capabilities. It supports the
                                 downloading and exporting of employee lists for reporting and analysis purposes. Additionally, it includes features to archive
                                 and deactivate employee profiles as needed, maintaining a clean and current database.
                              </div>
                           </div>
                        </div>
                        <div className="card">
                           <div className="card-header" id="headingFour">
                              <button
                                 className={`btn btn-link ${activeIndex === 3 ? 'active' : ''}`}
                                 onClick={() => toggleAccordion(3)}
                                 aria-expanded={activeIndex === 3}
                              >
                                 Streamlining Administrative Processes
                              </button>
                           </div>

                           <div
                              id="collapseFour"
                              className={`collapse ${activeIndex === 3 ? 'show' : ''}`}
                              aria-labelledby="headingFour"
                              data-parent="#accordion"
                           >
                              <div className="card-body">
                                 By adopting the Employee & HR Management module, organisations can streamline administrative tasks, improve data accuracy, and
                                 boost workforce efficiency, positioning themselves for success in a competitive business environment. The portal's blend of
                                 intuitive interface, advanced communication tools, and robust data management capabilities makes it an indispensable tool for
                                 modern businesses.
                              </div>
                           </div>
                        </div>
                        <div className="card">
                           <div className="card-header" id="headingFive">
                              <button
                                 className={`btn btn-link ${activeIndex === 4 ? 'active' : ''}`}
                                 onClick={() => toggleAccordion(4)}
                                 aria-expanded={activeIndex === 4}
                              >
                                 Empower Your Workforce Management
                              </button>
                           </div>

                           <div
                              id="collapseFive"
                              className={`collapse ${activeIndex === 4 ? 'show' : ''}`}
                              aria-labelledby="headingFive"
                              data-parent="#accordion"
                           >
                              <div className="card-body">
                                 Discover how the Employee Management module by Get Shifts can simplify your administrative processes, enhance communication,
                                 improve decision-making capabilities, and increase organisational efficiency. With its comprehensive features and user-friendly
                                 design, the portal is not just a tool but a strategic asset for optimising workforce management, underlining Get Shift's
                                 commitment to delivering innovative solutions that drive operational success and competitive advantage in today’s fast-paced
                                 business landscape.
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 align-self-center">
                     <div className="employee_accordion_img">
                        <img src={employee_accordion} loading="lazy" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/* <GetStartButton buttonStyle={{ width: '14%' }} wraperStyle={{ textAlign: 'center' }} /> */}
         <section className="feature_content">
            <div className="container">
               <div className="row mt-5">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <div className="feature__img--img">
                           <img src={all_feature} alt="all_feature" loading="lazy" />
                        </div>
                        <h6>Customisation, Integration, and Advanced Features</h6>
                        <p>
                           Organisations can customise the portal to include specific fields that capture information pertinent to their operations, such as
                           professional certifications and emergency contact details. Seamless integration with other business systems, like payroll software or
                           scheduling platforms, ensures consistency and accuracy across all data points.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <div className="feature__img--img">
                           <img src={streamline_process} alt="streamline_process" loading="lazy" />
                        </div>
                        <h6>Streamlining Administrative Processes</h6>
                        <p>
                           By adopting the Employee Management Portal, organisations can streamline administrative tasks, improve data accuracy, and boost
                           workforce efficiency, positioning themselves for success in a competitive business environment. The portal's blend of intuitive
                           interface, advanced communication tools, and robust data management capabilities makes it an indispensable tool for modern
                           businesses.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <div className="feature__img--img">
                           <img src={overview_db} alt="overview_db" loading="lazy" />
                        </div>
                        <h6>Comprehensive Overview and Data Management</h6>
                        <p>
                           The portal provides a comprehensive overview of employees, enhancing visibility and management capabilities. It supports the
                           downloading and exporting of employee lists for reporting and analysis purposes. Additionally, it includes features to archive and
                           deactivate employee profiles as needed, maintaining a clean and current database.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <div className="feature__img--img">
                           <img src={workforce_mangement} alt="workforce_mangement" loading="lazy" />
                        </div>
                        <h6>Empower Your Workforce Management</h6>
                        <p>
                           Discover how the Employee Management Portal by Get Shifts can simplify your administrative processes, enhance communication, improve
                           decision-making capabilities, and increase organisational efficiency. With its comprehensive features and user-friendly design, the
                           portal is not just a tool but a strategic asset for optimising workforce management, underlining Get Shift's commitment to delivering
                           innovative solutions that drive operational success and competitive advantage in today’s fast-paced business landscape.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <GetStartCTATrail />
         {/* <section>
                <div className="container">
                  <div className="features_cta">
                      
                        <div className="right-features_cta">
                            <h5>"Effortless Employee Management: Streamline Your Team with Ease"</h5>
                            <p>Let Get Shifts handle the staffing details, so you can concentrate on the heart of your business</p>
                            <div className="features_cta_btn">
                              <Link to="/signup">Get Started - <span style={{ fontSize: "12px" }}>It's free</span></Link>
                            </div>
                        </div>

                      
                  </div>
                </div>
            </section> */}
         <Footer />
      </>
   )
}

export default Employees
