import React from 'react'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import Header from '../header'
import Footer from '../NewFooter'
import './features.scss'
// Images
import shiftmanagement from '../../../images/feature-new/shift_management.png'
// import featuremincard1 from '../../../images/feature-new/feature-mini-card1.png'
import featureShiftManagement from '../../../images/feature-new/feature_shiftManagement.png'
import shift_ease_of_use_img from '../../../images/feature-new/shifts/ease_to_use.svg'
import shift_customisable_features from '../../../images/feature-new/shifts/customisable_features.svg'
import shift_real_time_communication from '../../../images/feature-new/shifts/real_time_communication.svg'
import shift_accessibility_svg from '../../../images/feature-new/shifts/shift_accessibility.webp'
import shift_why_choose_svg from '../../../images/feature-new/shifts/why_choose.webp'
import { GetStartButton } from './index'
const ShiftManagementssss = () => {
   return (
      <>
         <MetaTags>
            <title>Simplify Operations with Shift Management Software - Getshifts</title>
            <meta
               name="keyword"
               content="best shift management software, shift management software for employee,online shift management software, shift Scheduling software"
            />
            <meta
               name="description"
               content="Optimize your workforce scheduling facile with our advanced shift management software. Streamline employee scheduling, track attendance, and ensure seamless communication, all in one user-friendly platform. Try it now for enhanced productivity and efficiency"
            />
         </MetaTags>
         <Header />
         {/* <section className="cover-banners">
               
               <section className="home-banner">
						<div className="container">
							<div className="banner-parts feature_parts">
                        <div className="left-banner">
                           <h4 className="sht-list1">Effortless<br />Shift Management</h4>
                           <p>Streamline Staff Scheduling With Get Shifts Shift Management Module; The Central, Most Powerful And Intuitive Module Of The Get Shifts Platform That Lets You Create, Edit And View All Your Staff Shifts And Schedules Online.</p>
                           <div className="try-free">
                              <button type="button" className="btn tryforfree">
                                 <Link to="/signup">Get Started - <span style={{ fontSize: "12px" }}>It's free</span></Link>
                              </button>

                           </div>
                        </div>
							
								<div className="right_banners">
									<img src={shiftmanagement} alt="shiftmanagement" />
								</div>
							</div>


						</div>
					</section>
			</section>
         */}
         <section className="feature_content">
            <div className="container">
               <h5>
                  Streamlined Workforce <span>Management</span>
               </h5>
               <div className="feature_content_info">
                  <div className="feature_content_box">
                     <p>
                        The Shift Management Module, developed by Get Shifts, is an innovative solution designed to revolutionise workforce management by
                        facilitating the seamless scheduling of shifts and effective management of staff requirements. This advanced module serves as a central
                        hub, enabling businesses to efficiently create, edit, and oversee all staff shifts and schedules through an online platform, thus
                        eliminating the reliance on fragmented systems or cumbersome manual processes.
                     </p>
                  </div>
                  <div className="feature_content_img">
                     <img src={shiftmanagement} alt="Shift Management" loading='lazy' />
                  </div>
               </div>
               <p>
                  At the core of the Shift Management Module's appeal is its user-friendly interface, which is intuitively designed to accommodate users of
                  varying technical skill levels. This ease of use ensures that all team members, regardless of their familiarity with digital tools, can
                  navigate and utilise the system effectively, thereby enhancing operational efficiency and reducing the likelihood of errors.
               </p>
               <p>
                  Flexibility and customisation are paramount features of the Shift Management Module. Recognising the diversity in business operations and the
                  unique demands of different industries, Get Shifts has engineered this module to be highly adaptable. Businesses can customise shift schedules
                  to align with their specific operational needs, considering factors such as employee availability, role requirements, and workload
                  fluctuations. This adaptability ensures that organisations can optimise their human resources, aligning staffing levels with operational
                  demands to maintain peak efficiency.
               </p>
               <p>
                  Real-time updates are integral to the functionality of the Shift Management Module, ensuring that all stakeholders are informed of schedule
                  changes as they occur. This feature is crucial for maintaining fluid communication and coordination within teams, particularly in fast-paced
                  environments where shift adjustments are frequent. Managers and employees alike benefit from instant access to schedule alterations, allowing
                  for proactive adjustments and minimising disruptions to operations.
               </p>
               <div className="feature_content_info">
                  <div className="feature_content_img">
                     <img src={featureShiftManagement} alt="Shift Management" loading='lazy' />
                  </div>
                  <div className="feature_content_box">
                     <p>
                        The integration capabilities of the Shift Management Module further underscore its value to businesses. By seamlessly interfacing with a
                        variety of other software solutions, such as payroll systems, HR management platforms, and communication tools, the module acts as a
                        pivotal component within the broader business ecosystem. This interoperability streamlines workflows, reduces redundant efforts, and
                        consolidates data across platforms, enhancing overall productivity and operational coherence.
                     </p>
                  </div>
               </div>
               <p>
                  In addition to its primary functionalities, the Shift Management Module is designed with a forward-thinking approach, anticipating the
                  evolving needs of businesses. It offers scalable solutions that grow with the organisation, ensuring that the module remains an asset
                  regardless of business size or industry changes. The commitment to ongoing development and enhancement of the module reflects Get Shifts'
                  dedication to providing sustainable, long-term value to its users.
               </p>
               <p>
                  The implementation of the Shift Management Module signifies a strategic investment in workforce management, empowering businesses to harness
                  the benefits of digital transformation. By centralising control, simplifying processes, and providing a platform for informed decision-making,
                  the module plays a critical role in enhancing operational efficiency, boosting employee satisfaction, and ultimately contributing to the
                  success and profitability of the organisation.
               </p>
               <p>
                  The Shift Management Module by Get Shifts is not just a tool but a comprehensive solution for businesses seeking to optimise their workforce
                  management. With its blend of user-centric design, customisable features, real-time updates, and robust integration capabilities, the module
                  stands out as a pivotal innovation in the realm of business operations, offering a pathway to streamlined processes, improved efficiency, and
                  enhanced organisational performance.
               </p>
            </div>
         </section>
         <section>
            <div className="container">
               <div className="features_cta">
                  {/* <div className="left-features_cta">
                        <img src={featuremincard1} alt="featuremincard1" />
                     </div> */}
                  <div className="right-features_cta">
                     <h5>"Streamline Your Schedule: Effortless Shift Management Solutions"</h5>
                     <p>Let Get Shifts handle the staffing details, so you can concentrate on the heart of your business</p>
                     <div className="features_cta_btn">
                        <Link to="/signup">
                           Get Started - <span style={{ fontSize: '12px' }}>It's free</span>
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Footer />
      </>
   )
}
// accessibility

const ShiftManagements = () => {
   return (
      <>
         <MetaTags>
            <title>Simplify Operations with Shift Management Software - Getshifts</title>
            <meta
               name="keyword"
               content="best shift management software, shift management software for employee,online shift management software, shift Scheduling software"
            />
            <meta
               name="description"
               content="Optimize your workforce scheduling facile with our advanced shift management software. Streamline employee scheduling, track attendance, and ensure seamless communication, all in one user-friendly platform. Try it now for enhanced productivity and efficiency"
            />
         </MetaTags>
         <Header />
         <section className="cover-banners shift_cover_banners">
            <section className="home-banner">
               <div className="container">
                  <div className="banner-parts feature_parts">
                     <div className="left-banner">
                        <h4 className="sht-list1 new_sht-list1">Shift Management</h4>
                        <h6>Integrated Shift Management</h6>
                        <p>
                           The Shift Management Module by Get Shifts revolutionises workforce management with its innovative approach to shift scheduling and
                           staff oversight. As a centralised online platform, it replaces outdated systems and manual methods, enabling efficient management of
                           all scheduling aspects.
                        </p>
                        <GetStartButton wraperStyle={{ textAlign:"left" }} buttonStyle={{width:'30%'}}/>
                     </div>

                     <div className="right_banners">
                        <img src={shiftmanagement} alt="employee" loading='lazy' />
                     </div>
                  </div>
               </div>
            </section>
         </section>
         <section className="feature_content shift_feature_content">
            <div className="container">
               <div className="row mt-5">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4">
                     <div className="employee_accordion shift_accessibility ">
                        <ShiftAccessibilityCard
                           icon={shift_ease_of_use_img} // Replace with actual icon path
                           title="Ease of Use"
                           description={
                              <>
                                 Designed with a user-friendly interface, the module caters to all levels of technical proficiency, ensuring that every team
                                 member can manage shifts effectively. This approach reduces the likelihood of errors and boosts overall operational efficiency.
                              </>
                           }
                        />
                        <ShiftAccessibilityCard
                           icon={shift_customisable_features} // Replace with actual icon path
                           title="Customisable Features"
                           description={
                              <>
                                 Tailored to meet the diverse needs of various industries, the Shift Management Module offers exceptional flexibility.
                                 Organisations can adjust shift schedules based on specific factors like employee availability and workload, ensuring staffing
                                 levels are always aligned with operational requirements.
                              </>
                           }
                        />
                        <ShiftAccessibilityCard
                           icon={shift_real_time_communication} // Replace with actual icon path
                           title="Real-Time Communication"
                           description={
                              <>
                                 Key to the module's functionality is its ability to update schedules in real-time, keeping all stakeholders informed of changes
                                 immediately. This feature is crucial in maintaining seamless communication and coordination, particularly in dynamic
                                 environments where shift changes are frequent.
                              </>
                           }
                        />
                        <div className="feature_image">
                           <img src={shift_accessibility_svg} alt="shift_accessibility_svg" loading='lazy' />
                        </div>
                        <ShiftAccessibilityCard
                           icon={shift_real_time_communication} // Replace with actual icon path
                           title="Integration and Workflow"
                           description={
                              <>
                                 The module seamlessly integrates with other business software, including payroll systems and HR platforms. This
                                 interoperability streamlines workflows, reduces redundant tasks, and consolidates data, enhancing productivity and coherence
                                 across business operations.
                              </>
                           }
                        />
                        <ShiftAccessibilityCard
                           icon={shift_real_time_communication} // Replace with actual icon path
                           title="Scalability and Innovation"
                           description={
                              <>
                                 Designed to grow with your business, the module adapts to your evolving needs. Get Shifts commits to ongoing enhancements,
                                 ensuring the module continues to provide long-term value and adapt to market changes.
                              </>
                           }
                        />
                     </div>
                     <WhyChoose />
                  </div>
               </div>
            </div>
         </section>

         <Footer />
      </>
   )
}

const ShiftAccessibilityCard = ({ icon, title, description = <></> }) => {
   return (
      <div className="shift_accessibility_card">
         <div className="shift_accessibility_card-icon">
            <img src={icon} alt={title} loading='lazy' />
         </div>
         <h3 className="shift_accessibility_card-title">{title}</h3>
         <p className="shift_accessibility_card-description">{description}</p>
      </div>
   )
}

const WhyChoose = () => {
   return (
      <section className="why-choose-section">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-md-8">
                  <h2 className="why-choose-heading">
                     Why Choose
                     <br />
                     Get Shift’s Shift Management?
                  </h2>
                  <p className="why-choose-description">
                     Choosing the Shift Management Module is a strategic decision that enhances workforce management. It simplifies processes, centralizes
                     control, and supports informed decision-making, significantly improving operational efficiency and employee satisfaction. This tool is not
                     just a module but a comprehensive solution that fosters streamlined operations, enhanced efficiency, and superior organizational
                     performance.
                  </p>
                  <p>
                     <button type="button" className="btn tryforfree">
                        <Link to="/signup">Get started for free</Link>
                     </button>
                  </p>
                  
               </div>
               <div className="col-md-4">
                  <img src={shift_why_choose_svg} alt="Illustration" className="why-choose-image" loading='lazy' />
               </div>
            </div>
         </div>
      </section>
   )
}





export default ShiftManagements
